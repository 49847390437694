const isLocal = 0;
const isCncDev = 0;
const isMCM = 0;
const isVpn = 0;
const isAnmco = 0;
const isProduction = 0;
const isAoTrauma = 0;
const isMasterCardio = 0;
const isCncgroup = 0;
const isV2 = 0;
const isCncevents = 1;

let backendPath = "";
let keycloackPath = "";
let keycloackRealm = undefined;
let backendPort = "8082";
let keycloakPort = "10000";

if(isLocal){
  backendPath = "http://192.168.1.211"
  keycloackPath = "http://192.168.1.211"

}

else if(isCncDev){
  backendPath = "http://3.126.173.160"
  keycloackPath = "http://3.126.173.160"

}
else if(isVpn){
    backendPath = "http://172.16.1.211"
    keycloackPath = "http://172.16.1.211"
}
else if(isMCM){
    backendPort = undefined
    keycloakPort = undefined
    backendPath = "https://api.mcmweb.it"
    keycloackPath = "https://auth.mcmweb.it"
}
else if(isMasterCardio){
    backendPort = undefined
    keycloakPort = undefined
    backendPath = "https://api-mastercardio.mcmweb.it"
    keycloackPath = "https://auth.mcmweb.it"
    keycloackRealm = 'mastercardio-realm'
}
else if(isCncgroup){
    backendPort = undefined
    keycloakPort = undefined
    backendPath = "https://api.cncmultimedia.it"
    keycloackPath = "https://auth.cncmultimedia.it"
    keycloackRealm = 'cnc-group-realm'
}
else if(isV2){
  backendPort = undefined
  keycloakPort = undefined
  backendPath = "https://dev-api.cncmultimedia.it"
}
else if(isCncevents){
  backendPort = undefined
  keycloakPort = undefined
  backendPath = "https://api.cnc.events"
}
else if(isAnmco || isAoTrauma) {
    backendPath = "http://35.152.101.234"
    keycloackPath = "http://35.152.101.234"

  if(isAoTrauma){
    backendPort = "8182";
    keycloakPort = "11000";
  }
}

else if(isProduction){

}




const backendServices = [backendPath, backendPort].filter(x => !!x).join(":") + "/";

const keycloackService = [keycloackPath, keycloakPort].filter(x => !!x).join(":") +
  `/auth/realms/${keycloackRealm || "involving-realm"}/protocol/openid-connect/token`;
const subpath = "involving/api/"

/** PRODUCTION CNC */
/*
const backendServices = "http://ec2-54-86-10-141.compute-1.amazonaws.com:8082/";
const keycloackService = "http://ec2-54-147-83-51.compute-1.amazonaws.com:10000/auth/realms/talk-realm/protocol/openid-connect/token";
*/

const servicePath = backendServices+subpath

/**
 * Per avviare il progetto eseguire i seguenti comandi:
 * nvm use v14.18.1
 * REACT_APP_ENV=dev npm run start:anyway
  */

export default {
  backendServices,
  "baseEndpoint": servicePath,
  "loginEndpoint": servicePath+"auth/login",
  "tokenEndpoint": servicePath+"auth/token",
  "userEndpoint": servicePath+"user",
  "userCongressEndpoint": servicePath+"userincongress",
  "gatewayRoomEndpoint": servicePath+"gatewayroom",
  "usersEndpoint": servicePath+"user/all",
  "congressesEndpoint": servicePath+"congress",
  "beaconsEndpoint": servicePath+"beacon",
  "roomsEndpoint": servicePath,
  "clinicCasesEndpoint": servicePath,
  "gatewayEndpoint": servicePath+"gateway",
  "occurrenceSurveyEndpoint": servicePath+"occurrence-survey",

  "categoriesEndpoint": +"category/",
  "posts": servicePath+"post/",
  "media": servicePath+"media",
  "headersEndpoint": servicePath+"pageheader/",
  "sectionsEndpoint": servicePath+"section/",
  "conferencesEndpoint": servicePath+"conference/",
  "membersEndpoint": servicePath+"staffmember/",

  "verifyUserEndpoint": servicePath+"user/state",
  "slidersEndpoint": servicePath+"slider/",
  "sidebarEndpoint": servicePath+"sidebar/",
  "streamingsEndpoint": servicePath+"streaming/",
  "channelsEndpoint": servicePath+"channel/",
  "sponsorsEndpoint": servicePath+"sponsor/",
  "facultyEndpoint": servicePath+"faculty/",
  "minicorsoEndpoint": servicePath+"minicorso",
  "websocketEndpoint": servicePath+"websocket",
}
